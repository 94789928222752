const slides = document.querySelectorAll(".slide");
let mysliders = [];

const activeLoop = true;

const showSlides = () => {
    if (!slides) return;

    slides.forEach((slide, index) => {
        const items = slide.querySelectorAll(".swiper-slide");
        if (items.length <= 1) return;

        const efecte = slide.dataset.efecte ?? "fade";

        let myslider;

        const handleVideoEnded = (ev) => {
            ev.target.currentTime = 0;
            myslider.slideNext();
        };

        const playSlideOnVideoEnd = async (video) => {
            video.currentTime = 0;
            video.play();
            video.addEventListener("ended", handleVideoEnded, false);
        };

        const removeOldEventListener = (e, slide) => {
            const oldSlideActive = slide.querySelector(
                `.swiper-slide[data-swiper-slide-index="${
                    e.previousIndex - 1
                }"]`
            );

            if (oldSlideActive) {
                const videoOld = oldSlideActive.querySelector("video");
                if (videoOld) {
                    videoOld.removeEventListener(
                        "ended",
                        handleVideoEnded,
                        false
                    );
                }
            }
        };

        let configInit = {
            autoHeight: slide.dataset.visualitzacio == "resize" ? true : false,
            initialSlide: 0,
            loop: activeLoop,
            pagination: {
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: slide.dataset.pausetime ? slide.dataset.pausetime : 5000,
                autoplayDisableOnInteraction: false,
            },
            on: {
                beforeTransitionStart: function (e) {
                    const slideActive = slide.querySelector(
                        ".swiper-slide-active"
                    );

                    // Si és un video li afegitm un eventlistener per canviar d'slide un cop hagi acabat
                    const video = slideActive.querySelector("video");
                    if (video) {
                        playSlideOnVideoEnd(video);
                    }

                    // Setegem la propietat "autoplay" de l'slide si té video o no
                    if (mysliders[index] !== undefined) {
                        if (video) {
                            mysliders[index].autoplay.stop();
                        } else {
                            mysliders[index].autoplay.start();
                        }
                    }

                    //Eliminem els captions i el clonem perquè torni a fer l'efecte amb el wow
                    const caption = slideActive.querySelector(".caption");

                    if (caption) {
                        const newNode = caption.cloneNode(true);
                        caption.insertAdjacentElement("beforebegin", newNode);
                        caption.remove();
                    }
                },
                transitionEnd: (e) => {
                    removeOldEventListener(e, slide);
                },
            },
        };

        // Afegim l'efecte que volem
        let customEffect = {};
        switch (efecte) {
            case "fade":
                customEffect = {
                    effect: "fade",
                    fadeEffect: { crossFade: true },
                };
                break;

            case "vertical":
                customEffect = {
                    direction: "vertical",
                };
                break;

            case "cube":
                customEffect = {
                    effect: "cube",
                    grabCursor: true,
                    cubeEffect: {
                        shadow: true,
                        slideShadows: true,
                        shadowOffset: 20,
                        shadowScale: 0.94,
                    },
                };
                break;

            case "coverflow":
                customEffect = {
                    effect: "coverflow",
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 3,
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    },
                };
                break;
        }

        // Afegim totes les noves propietats
        let config = { ...configInit, ...customEffect };

        mysliders[index] = new Swiper(slide, config);

        myslider = mysliders[index];
    });
};

document.addEventListener("DOMContentLoaded", async (e) => {
    if (!slides) return;

    let videoFirstSlide = [];

    // Generem l'slide si tenim més d'un element
    slides.forEach((slide, index) => {
        // Comprovem si el primer element és un video
        const firstSlide = slide.querySelector(`.swiper-slide[data-id="0"]`);
        videoFirstSlide[index] = firstSlide.querySelector("video");

        const items = slide.querySelectorAll(".swiper-slide");
        if (items.length <= 1) {
            if (videoFirstSlide[index]) videoFirstSlide[index].loop = true;
            return;
        }
    });

    // Generem l'slide
    showSlides();

    slides.forEach((slide, index) => {
        // Parem l'autoplay si el primer element de l'slide és un video
        if (videoFirstSlide[index]) {
            mysliders[index].autoplay.pause();
        }
    });
});
